<script setup lang="ts">
import { debounce } from 'lodash'
import { computed, inject, ref } from 'vue'

import { Tooltip } from '@epostbox/ui/tooltip'

import { cn, groupStyleClasses, groupWrapperClasses } from '../../utils'
import { Paragraph } from '../paragraph'

import PasswordToggleEye from './password-toggle-eye.vue'

import {
  inputWrapperVariants,
  inputVariants,
  inputLabelVariants,
  inputContainerVariants,
  iconVariants,
  CustomInputProps,
} from '.'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<CustomInputProps & { debounceTime?: number }>()
const input = ref<HTMLInputElement>()
const isPasswordVisible = ref(false)

const modelValue = defineModel<string | number>()
const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
}>()

const isInputGroup = inject<boolean>('input-group', false)
const onUpdateDataDebounced = debounce((e: Event) => {
  emits('update:modelValue', (e.target as HTMLInputElement)?.value)
}, props.debounceTime ?? 0)

const typeComputed = computed(() => (isPasswordVisible.value ? 'text' : props.type))
</script>

<template>
  <label :for="name" :class="cn('relative w-full flex-1', groupWrapperClasses(isInputGroup!))" @click="input?.focus()">
    <div
      :class="
        cn(
          'relative flex w-full items-center',
          inputWrapperVariants({ color, size }),
          disabled ? 'cursor-not-allowed opacity-60' : 'cursor-text opacity-100',
          $attrs.class ?? '',
          groupStyleClasses(isInputGroup!, error),
          !isInputGroup && error ? 'border-error-650' : ''
        )
      "
    >
      <div :class="cn('w-full', inputContainerVariants())">
        <input
          :id="name"
          ref="input"
          :value="modelValue"
          :type="typeComputed"
          v-bind="$attrs"
          :inputmode="inputmode"
          :placeholder="placeholder || ''"
          :default-value="defaultValue"
          :name="name"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :readonly="readonly"
          :pattern="pattern"
          :min="min"
          :step="step"
          :maxlength="max"
          :class="cn(inputVariants({ color, size }), disabled ? 'pointer-events-none' : '', !label ? 'pt-0' : '')"
          @input="onUpdateDataDebounced"
        />
        <component
          :is="icon"
          v-if="icon"
          :for="name"
          :class="
            cn(
              'mt-5 h-5 w-5 min-w-5 text-gray-500 transition-all duration-75 peer-placeholder-shown:invisible peer-focus-within:visible',
              iconClasses
            )
          "
        />
        <span v-if="label" :class="cn(inputLabelVariants(), error ? '!text-error-650' : '')">
          {{ label }}
          <span v-if="required">*</span>
        </span>
      </div>
      <div class="flex items-center gap-x-2.5">
        <slot />
        <Tooltip
          v-if="error"
          content-side="bottom"
          trigger-as-tag="span"
          :trigger-class="['ml-2', { 'pointer-events-none': size !== 'sm' }]"
        >
          <template #trigger>
            <INolasAlertCircle v-if="error" :class="cn(iconVariants({ size }), 'cursor-pointer text-error-650')" />
          </template>
          <template v-if="error && size === 'sm'" #content>
            <p>{{ $t(error as string) }}</p>
          </template>
        </Tooltip>
        <INolasSlashCircle v-if="disabled" :class="cn(iconVariants({ size }), 'ml-4 text-gray-400')" />

        <PasswordToggleEye v-else-if="type === 'password'" v-model="isPasswordVisible" />
      </div>
    </div>
    <Paragraph
      v-if="description"
      size="xs"
      class="mt-1 font-semibold leading-4.5 text-gray-600"
      :class="[{ 'text-error-650': error }, { 'text-gray-450': disabled }]"
    >
      {{ description }}
    </Paragraph>
  </label>
</template>
