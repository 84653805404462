<script lang="ts" setup>
import { Label, type LabelProps } from 'radix-vue'

import { cn } from '../../utils'

import { type FormItemProps } from './'
import { useFormField } from './use-form-field'

defineProps<LabelProps & FormItemProps>()

const { formItemId } = useFormField()
</script>

<template>
  <Label
    :class="
      cn(
        'block text-left text-sm font-medium leading-5 text-gray-600',
        { 'flex-1': orientation === 'horizontal' },
        $attrs.class ?? ''
      )
    "
    :for="formItemId"
  >
    <slot />
  </Label>
</template>
