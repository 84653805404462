import type { Updater } from '@tanstack/vue-table'
import { cva } from 'class-variance-authority'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { type Ref } from 'vue'

import type { ErrorMessage } from './components/form'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function valueUpdater<T extends Updater<unknown>>(updaterOrValue: T, ref: Ref) {
  ref.value = typeof updaterOrValue === 'function' ? updaterOrValue(ref.value) : updaterOrValue
}

export const groupStyleClasses = (isInputGroup: boolean, error?: ErrorMessage) => {
  return isInputGroup
    ? cn(
        'rounded-lg first:rounded-r-none middle:rounded-l-none   middle:rounded-r-none last:rounded-l-none',
        'middle:border-l-0',
        error ? 'first:border-error-650 middle:border-error-650 last:border-error-650' : ''
      )
    : ''
}

export const groupWrapperClasses = (isInputGroup: boolean) => {
  return cn('flex items-center', isInputGroup ? 'rounded-lg [&>*]:!rounded-l-lg' : '')
}

export const groupVariants = cva('hover:bg-gray-200 disabled:text-gray-550', {
  variants: {
    color: {
      beige: 'bg-gray-100',
      white: 'bg-white',
    },
  },
  defaultVariants: {
    color: 'beige',
  },
})
