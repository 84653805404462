<script setup lang="ts">
import { cn } from '@epostbox/ui/utils'

import { usePasswordStrength } from '@composables/use-password-strength'

const { score } = usePasswordStrength()

const colorMap: Record<number, string> = {
  1: 'bg-error-600',
  2: 'bg-warning-600',
  3: 'bg-success-500',
  4: 'bg-success-600',
}
const e2e: Record<number, string> = {
  1: 'pass-level-1',
  2: 'pass-level-2',
  3: 'pass-level-3',
  4: 'pass-level-4',
}
</script>

<template>
  <div class="flex items-center gap-x-1 transition-all" data-e2="pass-levels">
    <span
      v-for="i in 4"
      :key="`password-score-${i}`"
      :class="cn('h-2 w-full rounded-full transition-all', i <= score ? colorMap[score] : 'bg-gray-150')"
      :data-e2e="i <= score ? e2e[score] : 'pass-level-0'"
    />
  </div>
</template>
